/*
This file is part of the Notesnook project (https://notesnook.com/)

Copyright (C) 2023 Streetwriters (Private) Limited

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
import { buttonVariants } from "./button.js";
import { inputVariants } from "./input.js";
import { textVariants } from "./text.js";
import { createFlexVariants } from "./flex.js";
export const variants = {
    buttons: buttonVariants,
    forms: inputVariants,
    text: textVariants,
    variants: Object.assign(Object.assign({}, createFlexVariants("row")), createFlexVariants("column"))
};
